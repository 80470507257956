import React from "react";
import { Container, Form, Button, Row, Col, ListGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "../styles/upload.scss";
import deleteIcon from "../img/delete.svg";

class Upload extends React.Component {
    variant = {
        WARNING: "warning",
        LIGHT: "light",
        SUCCESS: "success",
    };

    constructor(props) {
        super(props);
        this.state = {
            output: null,
            files: this.props.files,
        };
    }

    handleChange(e) {
        let newStateFiles = this.state.files;
        for (let i in e.target.files) {
            if (i !== "length" && i !== "item") {
                newStateFiles = newStateFiles.concat(e.target.files[i]);
            }
        }
        this.setState({ files: newStateFiles });
        e.target.value = null;
    }

    onFileDelete(i) {
        let files = this.state.files;
        files.splice(i, 1);
        this.setState({
            files: this.state.files,
        });
    }

    clearFiles() {
        this.setState({
            files: [],
        });
    }

    render() {
        const { t } = this.props;
        let fileItem = this.state.files.map((f, i) => {
            return (
                <ListGroup.Item key={i}>
                    <div>{f.name}</div>
                    <Button className="delete" variant="link" onClick={() => this.onFileDelete(i)}>
                        <img src={deleteIcon} alt={t("upload.deleteFile")} />
                    </Button>
                </ListGroup.Item>
            );
        });

        return (
            <Container className="upload-container">
                <Container className="upload-box">
                    <Col>
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <Form.Group>
                                <Container className="file-dropbox">
                                    <p>{t("upload.description")}</p>
                                    <p>{t("upload.descriptionOr")}</p>
                                    <Form.File className="btn-outline-primary btn file-input">
                                        <Form.File.Input
                                            id="file-input"
                                            onInput={(e) => this.handleChange(e)}
                                            multiple
                                        />
                                        <label>{t("upload.addFiles")}</label>
                                    </Form.File>
                                </Container>

                                <Container className="file-container">
                                    {this.state.files.length > 0 && (
                                        <Button
                                            onClick={() => this.clearFiles()}
                                            variant="outline-primary-small"
                                        >
                                            {t("upload.clearFilesButton")}
                                        </Button>
                                    )}
                                    <ListGroup variant="flush">{fileItem}</ListGroup>
                                </Container>
                            </Form.Group>

                            <Row>
                                <Button
                                    onClick={() => this.props.getResults(this.state.files)}
                                    id="btn-result"
                                    variant="gradient"
                                >
                                    <b>{t("upload.getResults")}</b>
                                </Button>
                            </Row>
                        </Form>
                    </Col>
                </Container>
            </Container>
        );
    }
}

export default withTranslation()(Upload);

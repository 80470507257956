import React, { Component } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { AppContext } from "./app-context";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            question: "",
            hidden: true,
        };
    }

    sendQuestion = (e) => {
        e.preventDefault();
        const app = this.context;
        axios({
            method: "post",
            url: process.env.REACT_APP_API_BASE_URL + "/contact",
            data: this.state,
        })
            .then((res) => {
                this.setState({
                    name: "",
                    email: "",
                    question: "",
                    hidden: true,
                });
                app.setAlert(this.props.t("home.question.success"), "success");
            })
            .catch((e) => {
                if (e.response) {
                    app.setAlert(e.response.data, "warning");
                } else {
                    app.setAlert(e.message, "warning");
                }
            });

        // While waiting for server
        app.setAlert("alert.processing", "light");
    };

    render() {
        const { t } = this.props;
        return (
            <Container id="contact">
                <h5>
                    <b>{t("home.info.questionTitle")}</b>
                </h5>
                <p>{t("home.info.questionText")}</p>
                <Row>
                    <Col>
                        <Button
                            onClick={() =>
                                this.setState({
                                    hidden: !this.state.hidden,
                                })
                            }
                            variant="outline-secondary"
                            id="show-form"
                        >
                            {t("home.question.hideButton")}
                        </Button>

                        <Form hidden={this.state.hidden} onSubmit={this.sendQuestion}>
                            <Form.Group>
                                <Form.Control
                                    id="question-name"
                                    type="text"
                                    placeholder={t("home.question.name")}
                                    value={this.state.name}
                                    onChange={(e) =>
                                        this.setState({
                                            name: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <Form.Control
                                    id="question-email"
                                    type="email"
                                    placeholder={t("home.question.email")}
                                    value={this.state.email}
                                    onChange={(e) =>
                                        this.setState({
                                            email: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <Form.Control
                                    id="question-text"
                                    as="textarea"
                                    placeholder={t("home.question.content")}
                                    value={this.state.question}
                                    onChange={(e) =>
                                        this.setState({
                                            question: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <Button type="submit" id="contact-send">
                                    {t("home.question.submit")}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}
Contact.contextType = AppContext;

export default withTranslation()(Contact);

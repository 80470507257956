import React from "react";
import { Container, Table, Row, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Table as CustomTable } from "./table";
import Filter from "./filter";
import "../styles/tables.scss";

class PivotContainer extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <Container className="result-table">
                <Container>
                    <h3 className="description">
                        <b>{t("pivot.title")}</b>
                    </h3>
                    <Row>
                        <Filter
                            filterOn={"type"}
                            {...this.props}
                            data={this.props.data.raw_pivot.columns}
                        />
                        <Filter
                            filterOn={"phase"}
                            {...this.props}
                            data={this.props.data.raw_pivot.columns}
                        />
                    </Row>
                </Container>

                <PivotTable {...this.props} t={t} />
            </Container>
        );
    }
}

class PivotTable extends React.Component {
    index = {
        ID: 0,
        READING: 1,
    };
    columns = {
        PHASE: 0,
        TYPE: 1,
    };
    cell = {
        VALUE: 0,
        SIZE: 1,
    };
    details = {
        ID: 0,
        READING: 1,
        VALUE: 3,
        SYNTAX: 4,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    parsePivot(table) {
        let header = [[["pivot.phase", 2]], [["pivot.type", 2]], [["pivot.syntax", 2]]];
        let previous = table.columns[0][0];
        for (let i = 0; i < table.columns[0].length; i++) {
            let amount = 0;
            for (let j = 0; j < table.columns.length; j++) {
                let current = table.columns[j][i];
                if (j > 0 && current !== previous && amount) {
                    header[i].push([previous, amount]);
                    amount = 0;
                }
                previous = current;
                if (
                    (this.props.filters.type.has(table.columns[j][this.columns.TYPE]) ||
                        this.props.filters.type.size === 0) &&
                    (this.props.filters.phase.has(
                        table.columns[j][this.columns.PHASE].toString()
                    ) ||
                        this.props.filters.phase.size === 0)
                )
                    amount++;
            }
            if (amount) header[i].push([previous, amount]);
        }
        return header;
    }

    render() {
        const { t } = this.props;
        const json = this.props.data.raw_pivot;
        const pivot = this.parsePivot(json);
        const details = this.props.data.details;
        const modalData = (syntax) => {
            const allowed_syntax = syntax.replace(/\s+/g, "").split(",");
            const response = {
                columns: [],
                data: [],
            };

            json.index.forEach((head, col) => {
                response.columns.push(head[this.details.ID] + " " + head[this.details.READING]);

                let row = 0;
                details.data.forEach((utterance) => {
                    if (
                        utterance[this.details.ID] === head[this.details.ID] &&
                        utterance[this.details.READING] === head[this.details.READING] &&
                        allowed_syntax.includes(utterance[this.details.SYNTAX])
                    ) {
                        if (!response.data[row]) {
                            response.data.push(Array(json.index.length).fill(""));
                        }
                        response.data[row][col] = utterance[this.details.VALUE];
                        row++;
                    }
                });
            });
            return response;
        };
        const inEmptyColumn = (index) => {
            let array = json.data;
            for (let row of array) {
                if (row[index] !== 0) return false;
            }
            return true;
        };

        return (
            <Container className="table-container">
                <Table>
                    <thead>
                        {pivot.map((row, i) => (
                            <tr key={i}>
                                {row.map((cell, i) => {
                                    if (row[0][0] === "pivot.syntax" && i !== 0) {
                                        return (
                                            <th key={i} colSpan={cell[this.cell.SIZE]}>
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ [i]: true });
                                                    }}
                                                    href="#top"
                                                >
                                                    {t(cell[this.cell.VALUE])}
                                                </a>
                                                <Modal
                                                    show={this.state[i]}
                                                    onHide={() => this.setState({ [i]: false })}
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>
                                                            {t(
                                                                "pivot.modal." +
                                                                    cell[this.cell.VALUE]
                                                            )}
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <CustomTable
                                                            data={modalData(cell[this.cell.VALUE])}
                                                            t={t}
                                                            noTranslation
                                                            noSort
                                                        />
                                                    </Modal.Body>
                                                </Modal>
                                            </th>
                                        );
                                    } else
                                        return (
                                            <th key={i} colSpan={cell[this.cell.SIZE]}>
                                                {t(cell[this.cell.VALUE])}
                                            </th>
                                        );
                                })}
                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {json.data.map((row, i) => {
                            if (
                                this.props.filters.children.has(json.index[i][this.index.ID]) ||
                                this.props.filters.children.size === 0
                            )
                                return (
                                    <tr key={i}>
                                        <th>{json.index[i][this.index.ID]}</th>
                                        <th className="border-right">
                                            {t("pivot.reading") + json.index[i][this.index.READING]}
                                        </th>
                                        {row.map((cell, i) => {
                                            if (
                                                (this.props.filters.type.has(
                                                    json.columns[i][this.columns.TYPE]
                                                ) ||
                                                    this.props.filters.type.size === 0) &&
                                                (this.props.filters.phase.has(
                                                    json.columns[i][this.columns.PHASE].toString()
                                                ) ||
                                                    this.props.filters.phase.size === 0)
                                            ) {
                                                if (inEmptyColumn(i))
                                                    return (
                                                        <td key={i} className="marked">
                                                            {cell}
                                                        </td>
                                                    );
                                                return <td key={i}>{cell}</td>;
                                            }
                                            return null;
                                        })}
                                    </tr>
                                );
                            return null;
                        })}
                    </tbody>
                </Table>
            </Container>
        );
    }
}

export default withTranslation()(PivotContainer);

import { Component } from "react";
import { Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import axios from "axios";
import i18next from "i18next";
import Header from "./header";
import Footer from "./footer";
import Home from "./home";
import Upload from "./upload";
import Result from "./result";
import React from "react";
import Nav from "./nav";
import { AppContext } from "./app-context";
import "../styles/app.scss";

class App extends Component {
    variant = {
        WARNING: "warning",
        LIGHT: "light",
        SUCCESS: "success",
    };

    constructor(props) {
        super(props);
        this.state = {
            alertMessage: "",
            alertVariant: "",
            currentPage: "home",
            runningTimeout: 0,
            loggedIn: false,
            files: [],
        };

        this.globalFunc = {
            setAppState: (state) => this.setState(state),
            setAlert: this.setAlert,
        };
    }

    setAlert = (message, variant) => {
        clearTimeout(this.state.runningTimeout);

        // update alert details and page props
        this.setState({
            alertMessage: message,
            alertVariant: variant,
        });

        if (variant === "success")
            this.setState({
                runningTimeout: setTimeout(() => {
                    this.setState({
                        alertMessage: "",
                        alertVariant: "",
                    });
                }, 5000),
            });
    };

    getResults = (files = this.state.files, expressionsCnt) => {
        if (files.length) {
            window.gtag("event", "get_results", {
                status: "attempt",
                expressions_cnt: expressionsCnt,
            });
            let formData = new FormData();
            files.map((file) => formData.append("file[]", file, file.name));
            if (expressionsCnt) formData.append("expressionsCnt", expressionsCnt);
            this.setState({ expressionsCnt: expressionsCnt });

            axios({
                method: "post",
                url: process.env.REACT_APP_API_BASE_URL + "/results",
                data: formData,
                withCredentials: true,
            })
                .then((res) => {
                    window.gtag("event", "get_results", {
                        status: "success",
                        expressions_cnt: expressionsCnt,
                    });
                    this.setAlert(this.props.t("alert.updated"), this.variant.SUCCESS);
                    this.setState({
                        results: res.data,
                        currentPage: "result",
                        files: files,
                    });
                })
                .catch((e) => {
                    window.gtag("event", "get_results", {
                        status: "error",
                        expressions_cnt: expressionsCnt,
                    });
                    if (e.response) {
                        if (e.response.status === 406) {
                            this.setAlert(
                                this.props.t("alert.fileError", { error: e.response.data }),
                                this.variant.WARNING
                            );
                        } else {
                            this.setAlert(this.props.t(e.response.data), this.variant.WARNING);
                        }
                    } else {
                        this.setAlert(this.props.t(e.message), this.variant.WARNING);
                    }
                });

            // While waiting for server
            this.setAlert(this.props.t("alert.processing"), this.variant.LIGHT);
        } else {
            this.setAlert(this.props.t("alert.noFile"), this.variant.WARNING);
        }
    };

    getExcel = (filters) => {
        let files = this.state.files;
        window.gtag("event", "download", {
            status: "attempt",
        });

        let formData = new FormData();
        files.map((file) => formData.append("file[]", file, file.name));
        formData.append("children", JSON.stringify(Array.from(filters.children)));
        formData.append("structureType", JSON.stringify(Array.from(filters.type)));
        formData.append("structurePhase", JSON.stringify(Array.from(filters.phase)));
        if (this.state.expressionsCnt) formData.append("expressionsCnt", this.state.expressionsCnt);

        axios({
            method: "post",
            url: process.env.REACT_APP_API_BASE_URL + "/excel",
            data: formData,
            responseType: "blob",
            headers: { "Accept-Language": i18next.language },
            withCredentials: true,
        })
            .then((res) => {
                window.gtag("event", "download", {
                    status: "success",
                });

                this.setAlert(this.props.t("alert.download"), this.variant.SUCCESS);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.props.t("result.downloadFile"));
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((e) => {
                window.gtag("event", "download", {
                    status: "error",
                });
                if (e.response) {
                    this.setAlert(this.props.t(e.response.data), this.variant.WARNING);
                } else {
                    this.setAlert(this.props.t(e.message), this.variant.WARNING);
                }
            });

        // While waiting for server
        this.setAlert(this.props.t("alert.processing"), this.variant.LIGHT);
    };

    render() {
        let pages = {
            home: <Home />,
            upload: <Upload files={this.state.files} getResults={this.getResults} />,
            result: (
                <Result
                    results={this.state.results}
                    getResults={this.getResults}
                    getExcel={this.getExcel}
                />
            ),
        };

        return (
            <AppContext.Provider value={this.globalFunc}>
                <div className="app">
                    {this.state.loggedIn && <Nav hasResults={this.state.results !== undefined} />}
                    <Header />
                    {pages[this.state.currentPage]}
                    <Footer />
                    <Alert variant={this.state.alertVariant}>{this.state.alertMessage}</Alert>
                </div>
            </AppContext.Provider>
        );
    }
}

export default withTranslation()(App);

import { Component } from "react";
import { Chart, PieSeries, Legend, Tooltip, Title } from "@devexpress/dx-react-chart-bootstrap4";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import { EventTracker } from "@devexpress/dx-react-chart";
import React from "react";
import { withTranslation } from "react-i18next";

class PieChart extends Component {
    render() {
        const { t } = this.props;
        const { ID, Meting, syntaxes } = this.props.data;
        let total = 0;
        let phaseCount = {};
        syntaxes.data.forEach((row) => {
            phaseCount[row[2]] = phaseCount[row[2]] + 1 || 1;
            total++;
        });
        let pie_data = [];
        for (let i of Object.keys(phaseCount)) {
            pie_data.push({ phase: t("result.piechart.legend", { i: i }), value: phaseCount[i] });
        }

        return (
            <Chart data={pie_data}>
                <Title text={t("result.piechart.title", { name: ID, reading: Meting })} />
                <PieSeries valueField="value" argumentField={"phase"} />
                <Legend />
                <EventTracker />
                <Tooltip
                    contentComponent={({ text, ...props }) => {
                        const element = pie_data[props.targetItem.point];

                        const message = t("result.piechart.tooltip", {
                            num: element.value,
                            perc: Math.round((element.value / total) * 100),
                        });

                        return <div>{message}</div>;
                    }}
                />
            </Chart>
        );
    }
}

export default withTranslation()(PieChart);

import React from "react";
import {
    ArgumentAxis,
    Chart,
    ScatterSeries,
    SplineSeries,
    ValueAxis,
    Legend,
    ZoomAndPan,
    Tooltip,
} from "@devexpress/dx-react-chart-bootstrap4";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import { EventTracker, ValueScale, ArgumentScale } from "@devexpress/dx-react-chart";
import { Plugin } from "@devexpress/dx-react-core";
import { withTranslation } from "react-i18next";

class StatChart extends React.Component {
    defaultViewport = {
        argumentStart: Infinity,
        argumentEnd: 0,
        valueStart: Infinity,
        valueEnd: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            viewport: this.getViewport(),
        };
        this.changeViewport = (viewport) => this.setState({ viewport });
    }

    createGraph(stat) {
        const table = this.props.table;
        const ID = 0;
        const READING = 1;
        const idStat = table.columns.findIndex((o) => o === stat);

        if (idStat !== -1) {
            let childrenCount = {};
            let stats = [];
            for (let i = 0; i < table.data.length; i++) {
                let data = table.data[i];
                let value = { reading: data[READING] };
                if (
                    this.props.childrenSelected.has(data[ID]) ||
                    this.props.childrenSelected.size === 0
                ) {
                    value[data[ID]] = parseFloat(data[idStat]);
                    stats.push(value);

                    if (!childrenCount[data[ID]]) {
                        childrenCount[data[ID]] = 1;
                    } else {
                        childrenCount[data[ID]] += 1;
                    }
                }
            }
            return [stats, childrenCount];
        }
    }

    getViewport() {
        const graph_data = this.createGraph(this.props.stat)[0];
        let viewport = { ...this.defaultViewport };
        graph_data.forEach((dataPoint) => {
            let valueKey = Object.keys(dataPoint)[1];
            viewport.argumentStart = Math.min(viewport.argumentStart, dataPoint.reading);
            viewport.argumentEnd = Math.max(viewport.argumentEnd, dataPoint.reading);
            viewport.valueStart = 0;
            switch (this.props.stat) {
                case "TARSP":
                    viewport.valueEnd = 6;
                    break;
                case "TARSPp":
                    viewport.valueEnd = 10;
                    break;
                case "mluw":
                    viewport.valueEnd = 10;
                    break;
                default:
                    viewport.valueEnd = 10;
            }
            if (dataPoint[valueKey] > viewport.valueEnd)
                viewport.valueEnd = dataPoint[valueKey] + 1;
        });
        if (graph_data.length === 1 || viewport.argumentStart === viewport.argumentEnd) {
            viewport.argumentStart = 0;
            viewport.argumentEnd = graph_data[0].reading + 1;
        }
        return viewport;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // Set the viewport to not be undefined
        this.setState({
            viewport: this.getViewport(),
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set the viewport according to the data
        if (this.props !== prevProps) {
            this.setState({ viewport: this.getViewport() });
        }
    }

    render() {
        let graph_data = this.createGraph(this.props.stat);
        const names = graph_data[1];
        graph_data = graph_data[0];

        const getDomain = (min, max) => {
            if (max < 10) return [min, max, 10];
            return [min, max];
        };

        const series = Object.keys(names).map((child, i) => {
            return (
                <SplineSeries
                    key={i}
                    argumentField={"reading"}
                    valueField={child}
                    name={child}
                    pointComponent={(props) => (
                        <ScatterSeries.Point point={{ size: 10 }} {...props} />
                    )}
                    seriesComponent={(props) => (
                        <React.Fragment>
                            <SplineSeries.Path {...props} />
                            <ScatterSeries.Path {...props} />
                        </React.Fragment>
                    )}
                />
            );
        });

        return (
            <Chart data={graph_data}>
                <ValueScale
                    modifyDomain={() => {
                        const viewport = this.getViewport();
                        return getDomain(viewport.valueStart, viewport.valueEnd);
                    }}
                />
                <ArgumentScale
                    modifyDomain={() => {
                        const viewport = this.getViewport();
                        return getDomain(viewport.argumentStart, viewport.argumentEnd);
                    }}
                />
                <ArgumentAxis />
                <ValueAxis />
                <Plugin name="series">{series}</Plugin>
                <Legend />
                <ZoomAndPan
                    viewport={this.state.viewport}
                    onViewportChange={this.changeViewport}
                    interactionWithArguments={"none"}
                    interactionWithValues={"both"}
                />
                <EventTracker />
                <Tooltip
                    contentComponent={({ text, ...props }) => {
                        const graph_data = this.createGraph(this.props.stat)[0];
                        const maxDiff = 0.1;
                        // Showing all the points from the same reading that are close together
                        const stackedPoints = graph_data.map((dataPoint, i) => {
                            const value = Object.values(dataPoint)[1];
                            if (
                                dataPoint.reading === graph_data[props.targetItem.point].reading &&
                                Math.abs(
                                    value - Object.values(graph_data[props.targetItem.point])[1]
                                ) <= maxDiff
                            )
                                return (
                                    <div key={i}>
                                        {Object.keys(dataPoint)[1]}: {value}
                                    </div>
                                );

                            return null;
                        });

                        return <div>{stackedPoints}</div>;
                    }}
                />
            </Chart>
        );
    }
}

export default withTranslation()(StatChart);

import React from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { AppContext } from "./app-context";
import menuLogo from "../img/menu.svg";

function Nav(props) {
    const { t } = props;

    return (
        <AppContext.Consumer>
            {(app) => (
                <div className="nav">
                    <Dropdown>
                        <Dropdown.Toggle variant="dropdown">
                            <img src={menuLogo} alt="Navigation menu" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as="button"
                                onClick={() => app.setAppState({ currentPage: "home" })}
                            >
                                {t("nav.home")}
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="button"
                                onClick={() => app.setAppState({ currentPage: "upload" })}
                            >
                                {t("nav.upload")}
                            </Dropdown.Item>
                            {props.hasResults && (
                                <Dropdown.Item
                                    as="button"
                                    onClick={() => app.setAppState({ currentPage: "result" })}
                                >
                                    {t("nav.result")}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}
        </AppContext.Consumer>
    );
}

export default withTranslation()(Nav);

import React from "react";
import { Container, OverlayTrigger, Table as ReactTable, Tooltip } from "react-bootstrap";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import infoLogo from "../img/info-logo.svg";

class Table extends React.Component {
    render() {
        const { t, filters, noTranslation } = this.props;
        const { columns, data } = this.props.data;

        const index = {
            children: columns.indexOf("ID"),
            type: columns.indexOf("TypeStructuur"),
            phase: columns.indexOf("FaseStructuur"),
        };

        return (
            <Container className="table-container">
                <ReactTable>
                    <thead>
                        <tr>
                            {columns.map((column) => {
                                if (i18next.exists("table.tooltip." + column)) {
                                    return (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>{t("table.tooltip." + column)}</Tooltip>
                                            }
                                            key={column}
                                        >
                                            <th>
                                                {t("table." + column)}
                                                <img
                                                    src={infoLogo}
                                                    alt="logo"
                                                    className="info-logo"
                                                />
                                            </th>
                                        </OverlayTrigger>
                                    );
                                }
                                return (
                                    <th key={column}>
                                        {noTranslation ? column : t("table." + column)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((row, i) => {
                            for (let key in filters) {
                                const filter = filters[key];
                                const id = index[key];
                                if (id !== -1 && filter.size > 0 && !filter.has(row[id].toString()))
                                    return null;
                            }

                            return (
                                <tr key={i}>
                                    {row.map((value, i) => (
                                        <td key={i}>{value}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </ReactTable>
            </Container>
        );
    }
}

export { Table };
export default withTranslation()(Table);

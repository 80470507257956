import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import * as Msal from "msal";
import { withTranslation } from "react-i18next";
import { AppContext } from "./app-context";
import msIMG from "../img/ms.png";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginWithSSO: process.env.REACT_APP_LOGIN_WITH_SSO === "true",
            loginWithCode: process.env.REACT_APP_LOGIN_WITH_CODE === "true",
            pinShowInput: false,
        };

        this.config = {
            auth: {
                clientId: process.env.REACT_APP_CLIENT_ID,
                redirectUri: process.env.REACT_APP_REDIRECT_URI,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },
            graphScopes: ["user.read"],
        };

        this.MSALObj = new Msal.UserAgentApplication(this.config);
    }

    componentWillMount() {
        this.MSALObj.handleRedirectCallback((error, data) => {
            if (data.uniqueId) {
                const uniqueId = { uniqueId: data["uniqueId"] };
                let app = this.context;
                fetch(process.env.REACT_APP_API_BASE_URL + "/login", {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify(uniqueId),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            app.setAppState({ currentPage: "upload", loggedIn: true });
                        }
                    })
                    .catch((e) => {
                        app.setAlert(e, "warning");
                    });
            }
        });
    }

    loginSSO = () => {
        this.context.setAlert("Connecting", "light");
        this.MSALObj.loginRedirect(this.config.graphScopes);
    };

    loginCode = (e) => {
        e.preventDefault();
        let app = this.context;
        app.setAlert("Connecting", "light");
        const value = e.target[0].value;
        const code = { code: value };

        fetch(process.env.REACT_APP_API_BASE_URL + "/code_login", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(code),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    app.setAppState({ currentPage: "upload", loggedIn: true });
                    app.setAlert("", "");
                } else if (response.status === 400) {
                    app.setAlert("Invalid code", "warning");
                }
            })
            .catch((e) => {
                app.setAlert(e, "warning");
            });
    };

    render() {
        const { t } = this.props;

        const ssoLogin = (
            <>
                <p>{t("home.login.tip")}</p>
                <Button variant="primary" className="loginButton" onClick={this.loginSSO}>
                    <img src={msIMG} className="msLogo" alt="logo" />
                    {t("home.login.button")}
                </Button>
            </>
        );

        const pinLogin = this.state.pinShowInput ? (
            <Form onSubmit={this.loginCode}>
                <Form.Control type="text" id="pin-input" />
                <Button type="submit" id="pin-submit" variant="outline-primary-small">
                    {t("home.login.submit")}
                </Button>
            </Form>
        ) : (
            <Button
                variant="outline-primary-small"
                id="pin-btn"
                onClick={() => this.setState({ pinShowInput: true })}
            >
                {t("home.login.pinCode")}
            </Button>
        );

        return (
            <div>
                {this.state.loginWithSSO && ssoLogin}
                {this.state.loginWithCode && pinLogin}
            </div>
        );
    }
}
Login.contextType = AppContext;

export default withTranslation()(Login);

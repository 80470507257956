import React from "react";
import { Dropdown } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import StatChart from "./statchart";
import PieChart from "./piechart";

class Graph extends React.Component {
    stats = {
        TARSP: "TARSP",
        TARSPP: "TARSPp",
        MLUW: "mluw",
    };

    constructor(props) {
        super(props);
        this.state = {
            graphStat: this.stats.TARSP,
        };
    }

    handleGraphChange(e) {
        this.setState({
            graphStat: e,
        });
    }

    render() {
        const { t, pieData } = this.props;
        const statChoices = Object.values(this.stats).map((value, i) => (
            <Dropdown.Item onClick={() => this.handleGraphChange(value)} key={i} as="button">
                {t("table." + value)}
            </Dropdown.Item>
        ));

        const pieChoices = pieData.map((dict, i) => {
            if (dict.Meting > 1)
                return (
                    <Dropdown.Item onClick={() => this.handleGraphChange(i)} key={i} as="button">
                        {t("result.piechart.dropdownItem", dict)}
                    </Dropdown.Item>
                );
            return null;
        });

        return (
            <Container className="graphs">
                <Dropdown>
                    <Dropdown.Toggle variant="dropdown">
                        {Object.values(this.stats).includes(this.state.graphStat)
                            ? t("table." + this.state.graphStat)
                            : t("result.piechart.dropdownItem", pieData[this.state.graphStat])}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>{t("result.statDropdownHead")}</Dropdown.Header>
                        {statChoices}
                        <Dropdown.Divider />
                        <Dropdown.Header>{t("result.piechart.dropdownHead")}</Dropdown.Header>
                        {pieChoices}
                    </Dropdown.Menu>
                </Dropdown>
                {Object.values(this.stats).includes(this.state.graphStat) ? (
                    <StatChart
                        table={this.props.statData}
                        stat={this.state.graphStat}
                        childrenSelected={this.props.childrenSelected}
                    />
                ) : (
                    <PieChart data={pieData[this.state.graphStat]} />
                )}
            </Container>
        );
    }
}

export default withTranslation()(Graph);

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import languageEN from "./locales/en/translate.json";
import languageNL from "./locales/nl/translate.json";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: languageEN,
            nl: languageNL,
        },
        detection: {
            order: ["queryString", "navigator"],
            lookupQuerystring: "lng",
        },
        /* When react i18next does not find any language as default in browser */
        fallbackLng: "en",
        /* debugger For Development environment */
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            useSuspense: false,
            bindI18n: "languageChanged loaded",
            bindStore: "added removed",
            nsMode: "default",
        },
    });

export default i18n;

import React from "react";
import { Container, Row, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Table from "./table";
import Filter from "./filter";
import "../styles/result.scss";

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expressionCnt: "",
        };
    }

    onExpressionsChange(e) {
        let value = parseInt(e.target.value);
        if (Number.isInteger(value) && value > 0) {
            this.setState({
                expressionCnt: value,
            });
        } else if (!e.target.value) {
            this.setState({
                expressionCnt: "",
            });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Container className="children-stats">
                <Container>
                    <h3 className="description">
                        <b>{t("summary.title")}</b>
                    </h3>
                    <Row>
                        <Filter filterOn={"children"} {...this.props} data={this.props.data.data} />

                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.props.getResults(undefined, this.state.expressionCnt);
                            }}
                            inline
                        >
                            <OverlayTrigger
                                overlay={<Tooltip>{t("summary.expressionTooltip")}</Tooltip>}
                            >
                                <Form.Label>{t("summary.expressionCnt")}</Form.Label>
                            </OverlayTrigger>
                            <Form.Control
                                type="text"
                                placeholder={t("summary.inputPlaceholder")}
                                size="sm"
                                value={this.state.expressionCnt}
                                onChange={(e) => this.onExpressionsChange(e)}
                                id="update-input"
                            />
                            <Button type="submit" id="update-btn" variant="outline-primary-small">
                                {t("summary.update")}
                            </Button>
                        </Form>
                    </Row>
                </Container>

                <Table {...this.props} />
            </Container>
        );
    }
}

export default withTranslation()(Overview);

import React from "react";
import { Image, Container, Row, Col, Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import "../styles/header.scss";
import aurisLogo from "../img/auris_logo.jpg";
import enLogo from "../img/en-uk.png";
import nlLogo from "../img/nl-nl.png";

function Header(props) {
    const { t } = props;
    const language = {
        en: (
            <>
                <img src={enLogo} className="lang-logo" alt="logo" />
                {t("footer.language.en")}
            </>
        ),
        nl: (
            <>
                <img src={nlLogo} className="lang-logo" alt="logo" />
                {t("footer.language.nl")}
            </>
        ),
    };

    function changeLanguage(lang) {
        i18next.changeLanguage(lang);
    }

    if (!Object.keys(language).includes(i18next.language)) i18next.changeLanguage("en");

    return (
        <Container className="header" fluid>
            <Container>
                <Row>
                    <Col xs={1}>
                        <Image src={aurisLogo} id={"auris-logo"} />
                    </Col>
                    <Col className="col-container">
                        <h1 id="title">{t("header.title")}</h1>
                    </Col>
                    <Col xs={1}>
                        <Dropdown>
                            <Dropdown.Toggle variant="custom">
                                {language[i18next.language]}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => changeLanguage("en")}>
                                    {language["en"]}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage("nl")}>
                                    {language["nl"]}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default withTranslation()(Header);

import React from "react";
import { Container, Image } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "../styles/footer.scss";
import itsLanguageLogo from "../img/logo-itslanguage.svg";

function Footer(props) {
    const { t } = props;

    return (
        <Container className="footer" fluid>
            <Container>
                <p>
                    <a href="https://www.itslanguage.nl/spraak-taal-therapie/">
                        {t("footer.credits")} <Image src={itsLanguageLogo} id="itslanguage-logo" />
                    </a>
                </p>
            </Container>
        </Container>
    );
}

export default withTranslation()(Footer);

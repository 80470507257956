import { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class Filter extends Component {
    index = {
        children: 0,
        type: 1,
        phase: 0,
    };

    reset(e) {
        for (
            let element = e.target.nextSibling.nextSibling;
            element;
            element = element.nextSibling
        ) {
            element.classList.remove("selected");
        }
        this.props.setCurrentState({ [this.props.filterOn]: new Set() });
    }

    filter(e) {
        const { filterOn } = this.props;
        let { filters, setCurrentState } = this.props;
        if (e.target.classList.contains("selected")) {
            filters[filterOn].delete(e.target.innerText);
            setCurrentState({});
            e.target.classList.remove("selected");
        } else {
            setCurrentState({
                [filterOn]: filters[filterOn].add(e.target.innerText),
            });
            e.target.classList.add("selected");
        }
    }

    getDropdownItems() {
        const { data, filterOn } = this.props;
        const index = this.index[filterOn];
        let dropdownItems = new Set();
        for (let row of data) {
            dropdownItems.add(row[index].toString());
        }
        return dropdownItems;
    }

    render() {
        const { t, filterOn } = this.props;
        return (
            <Dropdown>
                <Dropdown.Toggle variant="dropdown">{t("table." + filterOn)}</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={(e) => this.reset(e)}>
                        {t("result.reset")}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {Array.from(this.getDropdownItems()).map((entry, i) => (
                        <Dropdown.Item key={i} onClick={(e) => this.filter(e)} as={"button"}>
                            {entry}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default withTranslation()(Filter);

import React from "react";
import { Button, Container, Tabs, Tab } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PivotContainer from "./pivot";
import Graph from "./graph-block";
import Overview from "./overview";
import Table from "./table";
import "../styles/result.scss";

class Result extends React.Component {
    variant = {
        WARNING: "warning",
        LIGHT: "light",
        SUCCESS: "success",
    };

    constructor(props) {
        super(props);
        this.state = {
            children: new Set(),
            type: new Set(),
            phase: new Set(),
        };
    }

    render() {
        const { t, results } = this.props;
        const result_summary = JSON.parse(results.header);
        const raw_pivot = JSON.parse(results.pivot);
        const struct = JSON.parse(results.struct);
        const regex = JSON.parse(results.regex);
        const ondw = JSON.parse(results.ondw);
        let diff = [];
        for (let reading of results.diff) {
            diff.push({ ...reading, syntaxes: JSON.parse(reading.syntaxes) });
        }

        return (
            <Container className="results-block">
                <Container id="excel">
                    <Button variant="gradient" onClick={() => this.props.getExcel(this.state)}>
                        <b>{t("result.getExcel")}</b>
                    </Button>
                </Container>

                <Overview
                    data={result_summary}
                    filters={this.state}
                    setCurrentState={(state) => this.setState(state)}
                    getResults={this.props.getResults}
                />

                <PivotContainer
                    data={{ raw_pivot: raw_pivot, details: struct }}
                    filters={this.state}
                    setCurrentState={(state) => this.setState(state)}
                />

                <Graph
                    statData={result_summary}
                    pieData={diff}
                    childrenSelected={this.state.children}
                />

                <Container>
                    <h5>
                        <b>{t("result.details.title")}</b>
                    </h5>
                    <Tabs defaultActiveKey="">
                        <Tab eventKey="struct" title={t("result.details.struct")}>
                            <Table data={struct} filters={this.state} />
                        </Tab>
                        <Tab eventKey="regex" title={t("result.details.regex")}>
                            <Table data={regex} filters={this.state} />
                        </Tab>
                        <Tab eventKey="ondw" title={t("result.details.ondw")}>
                            <Table data={ondw} filters={this.state} />
                        </Tab>
                        {diff.map((newSyntaxTable, i) => {
                            if (newSyntaxTable.Meting > 1)
                                return (
                                    <Tab
                                        eventKey={i}
                                        title={t("result.details.newSyntax", newSyntaxTable)}
                                    >
                                        <Table
                                            data={newSyntaxTable.syntaxes}
                                            filters={this.state}
                                        />
                                    </Tab>
                                );
                            return null;
                        })}
                    </Tabs>
                </Container>

                <Container>
                    <Button onClick={() => this.props.getExcel(this.state)}>
                        {t("result.getExcel")}
                    </Button>
                </Container>
            </Container>
        );
    }
}

export default withTranslation()(Result);

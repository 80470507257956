import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import "./i18n";
import "bootstrap/dist/css/bootstrap.css";
import * as Sentry from "@sentry/react";

if (typeof process.env.REACT_APP_SENTRY_DSN !== "undefined") {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
    });
}

ReactDOM.render(<App />, document.getElementById("root"));
